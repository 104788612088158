@import "./globalvars.scss";
@import './theme.scss'; 
@include custom-theme($my-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #f7f7f7 !important; }

app-alert {
    position: fixed;
    z-index: 1;
}

.uppercase {
    text-transform: uppercase;
}

.text-cancelled {
    text-decoration: line-through;
}

.mat-form-control {
    width: 100%;
}

.snack-danger {
  color: white !important;
  background-color: $main-color !important
}

.p-component {
    font-size: 14px !important;
}
.p-toast {
    width: 320px !important;
    font-size: 16px;
}
.p-button {
    font-size: 16px;
}
.p-dialog-content {
    font-size: 15px;
}
.p-datatable table {
    font-size: 14px;
}
.p-datatable .p-datatable-tbody > tr > td.text-right, .p-datatable .p-datatable-thead > tr > th.text-right, .p-datatable .p-datatable-tfoot > tr > td.text-right {
    text-align: right !important;
}
.p-datatable .p-datatable-tbody > tr > td.text-center, .p-datatable .p-datatable-thead > tr > th.text-center, .p-datatable .p-datatable-tfoot > tr > td.text-center {
    text-align: center !important;
}
th.top-header, tr.top-header th, tr.top-header td {
    background-color: #dde3e5 !important;
}
.pmessage .p-message-wrapper{
    display: block !important;
}
.p-confirm-dialog-reject, .p-button.p-confirm-dialog-reject:enabled:active, .p-button.p-confirm-dialog-reject:enabled:hover {
    background-color: #979797;
    border-color: #979797;
}
.p-confirm-dialog-reject:focus {
    box-shadow: 0 0 0 0.2rem #4d4d4d
}
.p-confirm-dialog .p-dialog-content {
    padding: 5px 25px 25px 15px;
}
.p-dialog .p-dialog-header {
    border-bottom: 1px solid #eaeaea;
}
.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 22px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    font-size: 14px;
}
.p-dialog-mask{
    z-index: 999 !important;
}
a.btn-link{
    cursor: pointer;
}

.flexcols{
    display: flex;
    gap: 8px;
    align-items: center;
}

h1.content-title{
    position: relative;
    margin-bottom: 45px !important;
}
h1.content-title:before {
    content: "";
    border-bottom: 4px solid #df6b00;
    width: 135px;
    position: absolute;
    bottom: -6px;
}
input[type="text"].form-control {
    height: 40px !important;
}
.form-group {
    margin-bottom: 25px !important;
}
button.btn-icon{
    outline: none;
    border: 0;
    background-color: transparent;
}

td.negative {
    color: rgba(255, 0, 0, 0.732) !important;
    font-weight: 500 !important;
}
.color-red {
    color: rgba(233, 15, 15, 0.534) !important;
}
.color-green {
    color: rgba(24, 152, 65, 0.732) !important;
}
.text-bold, td.text-bold {
    font-weight: 700 !important;
}
.danger-container {
    background-color: #fbabab;
    color: #2a2a2a;
}

.border-radius-top {border-top-left-radius: $border-radius;border-top-right-radius: $border-radius;}
.border-radius-bottom {border-bottom-left-radius: $border-radius;border-bottom-right-radius: $border-radius;}
.min-h-500 {min-height: 500px;}
.min-w-320 {min-width: 320px !important}
.min-w-160 {min-width: 160px !important}
.width-100 {width: 100%}
.margin-top-xl{margin-top:30px !important}
.margin-top-lg{margin-top:20px !important}
.margin-top-md{margin-top:12px !important}
.margin-bottom-sm{margin-bottom: 8px;}
.margin-bottom-md{margin-bottom:12px;}
.margin-bottom-lg{margin-bottom:20px;}
.vertical-top {vertical-align: top !important}
.fontsize-md {font-size: 16px}
.flex-row-container { 
    vertical-align: middle;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.flex-title {display: flex; align-items: center;}
.active-button{ color: $main-color !important}
.hds-padding {padding: 20px}
.hds-padding-md {padding: 12px}