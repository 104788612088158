$main-color: #df6b00;
$main-light-color: #feebde;
$dark-color: #3f4046;
$dark-mid-color: #5a5a5a;
$dark-light-color: #cccdce;
$ligth-dark-color: #666666;
$light-color: #f5f5f5;
$hds-color: #683334;
$hds-light-color: #624742;
$hds-dark-color: #132a48;
$border-radius: 10px;
$background: #f7f7f7;
$cards-bg: #ffffff;

$fontsize-h1: 22px;
$fontsize-h2: 20px;
$fontsize-h3: 18px;
$fontsize-h4: 16px;
$fontsize-h5: 14px;
$fontsize-h6: 12px;

$ashley-palette: (
  50: #fdf2e0,
  100: #f9dfb2,
  200: #f6ca80,
  300: #f3b44e,
  400: #f1a428,
  500: #ef9507,
  600: #eb8a04,
  700: #e57b02,
  800: #df6c00,
  900: #d55300,
  A100: #df6c00,
  A200: #df6c00,
  A400: #df6c00,
  A700: #df6c00,
  contrast: (
    50: #333333,
    100: #333333,
    200: #333333,
    300: #333333,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$ashley-secondary-palette: (
  50: #f8f9ff,
  100: #f3f4fc,
  200: #ebecf4,
  300: #dddee5,
  400: #babbc2,
  500: #626469,
  600: #727379,
  700: #5e5f65,
  800: #3f4046,
  900: #1e1f25,
  A100: #3f4046,
  A200: #3f4046,
  A400: #3f4046,
  A700: #3f4046,
  contrast: (
    50: #333333,
    100: #333333,
    200: #333333,
    300: #333333,
    400: #333333,
    500: #333333,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$ashley-warm-palette: (
  50: #ffebed,
  100: #feccd1,
  200: #ed9898,
  300: #e27170,
  400: #ec504c,
  500: #f03f32,
  600: #e13531,
  700: #cf2b2b,
  800: #c22424,
  900: #b31718,
  A100: #cf2b2b,
  A200: #cf2b2b,
  A400: #cf2b2b,
  A700: #cf2b2b,
  contrast: (
    50: #333333,
    100: #333333,
    200: #333333,
    300: #333333,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

// $mdThemingProvider.definePalette('hdspalette', {
//   '50': 'fbede0',
//   '100': 'f5d3b3',
//   '200': 'efb580',
//   '300': 'e9974d',
//   '400': 'e48126',
//   '500': 'df6b00',
//   '600': 'db6300',
//   '700': 'd75800',
//   '800': 'd24e00',
//   '900': 'ca3c00',
//   'A100': 'fff5f2',
//   'A200': 'ffcebf',
//   'A400': 'ffa68c',
//   'A700': 'ff9273',
//   'contrastDefaultColor': 'light',
//   'contrastDarkColors': [
//     '50',
//     '100',
//     '200',
//     '300',
//     '400',
//     '500',
//     'A100',
//     'A200',
//     'A400',
//     'A700'
//   ],
//   'contrastLightColors': [
//     '600',
//     '700',
//     '800',
//     '900'
//   ]
// });