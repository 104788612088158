@import '~@angular/material/theming';
@import '/src/globalvars.scss';
@include mat-core();

$my-app-primary: mat-palette($ashley-palette);
$my-app-accent:  mat-palette($ashley-secondary-palette);
$my-app-warn:    mat-palette($ashley-warm-palette);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

@mixin custom-theme($theme) {
  @include mat-button-theme($theme);
  @include mat-button-toggle-theme($theme);
  @include mat-card-theme($theme);
}

/*Quill*/
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

// $my-app-primary: mat.define-palette(mat.$ashley-palette, 800);
// $my-app-accent:  mat.define-palette(mat.$ashley-palette, 500, 900);
// $my-app-warn:    mat.define-palette(mat.$ashley-palette);
// $my-theme: mat.define-light-theme((
//  color: (
//    primary: $my-app-primary,
//    accent: $my-app-accent,
//    warn: $my-app-warn
//  )
// ));
// @include mat.all-component-themes($my-theme);